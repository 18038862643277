import { mixinPromise } from "@c@/helpers/mixin.promise";
import { debugConsole } from "@c@/helpers/helpers";
import {
  GET_ARTICLE_NOTIFICATION,
  GET_ARTICLE_NOTIFICATIONS,
  STORE_ARTICLE_NOTIFICATION,
  UPDATE_ARTICLE_NOTIFICATION
} from "@/store/modules/article.notification.module";
export  default {
  methods: {
    storeArticleNotification(article_id, formData, actions = {
      begin: ()=>{ this.$emit('loading'); debugConsole({message: 'storing'}); },
      after: (response, state)=>{ this.$emit('loaded', {response, state}); debugConsole({message: 'stored'}); },
    }){
      return mixinPromise(this.$store.dispatch(STORE_ARTICLE_NOTIFICATION, { article_id, formData }), actions)
    },
    getArticleNotification(id, actions = {
      begin: ()=>{ this.$emit('loading'); debugConsole({message: 'loading'}); },
      after: (response, state)=>{ this.$emit('loaded', {response, state}); debugConsole({message: 'loaded'}); },
    }){
      return mixinPromise(this.$store.dispatch(GET_ARTICLE_NOTIFICATION, id), actions)
    },
    updateArticleNotification(id, formData, actions = {
      begin: ()=>{ this.$emit('loading'); debugConsole({message: 'storing'}); },
      after: (response, state)=>{ this.$emit('loaded', {response, state}); debugConsole({message: 'stored'}); },
    }){
      return mixinPromise(this.$store.dispatch(UPDATE_ARTICLE_NOTIFICATION, { id, formData }), actions)
    },
    getArticleNotifications(article_id, page = 1, actions = {
      begin: ()=>{ this.$emit('loading'); debugConsole({message: 'loading'}); },
      after: (response, state)=>{ this.$emit('loaded', {response, state}); debugConsole({message: 'loaded'}); },
    }){
      return mixinPromise(this.$store.dispatch(GET_ARTICLE_NOTIFICATIONS, { article_id, page }), actions)
    }
  }
}
